/***********************************************/
// Capa de Servicios para el modulo de Hospitales.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/hospitals");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data: ", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-list/hospitals");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data: ", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/hospitals/${id}`);
            if (response.data.success) {
                // console.log("response.data: ", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData) {
        // console.log('Adding Record.', formData);
        try {
            // let specialtiesList = formData.specialties;
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("email", formData.email);
            recordData.append("address", formData.address);
            recordData.append("phone1", formData.phone1);
            recordData.append("phone2", formData.phone2);
            recordData.append("web_page", formData.web_page);
            recordData.append("sanitary_license", formData.sanitary_license);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("facebook_profile", formData.facebook_profile);
            recordData.append("instagram_profile", formData.instagram_profile);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("membership", formData.membership);
            recordData.append("servicehours", formData.servicehours);
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(this.#baseUrl + "/hospitals", recordData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                // let hospital_id = result.id;
                // Sincroniza las especialidades con el medico
                /* let specialtiesIds = [];
                specialtiesList.forEach(item => {
                    specialtiesIds.push(item.id);
                });
                this.registerSpecialties(hospital_id, specialtiesIds);*/
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Pushes posts to the server when called.
    async updateRecord(formData) {
        try {
            // console.log('Updating Record: ', formData);
            // let specialtiesList = formData.specialties;
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("email", formData.email);
            recordData.append("address", formData.address);
            recordData.append("phone1", formData.phone1);
            recordData.append("phone2", formData.phone2);
            recordData.append("web_page", formData.web_page);
            recordData.append("sanitary_license", formData.sanitary_license);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("facebook_profile", formData.facebook_profile);
            recordData.append("instagram_profile", formData.instagram_profile);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("membership", formData.membership);
            recordData.append("servicehours", formData.servicehours);
            recordData.append('_method', 'put');
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(`${this.#baseUrl}/hospitals/${formData.id}`, recordData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                // let hospital_id = result.id;
                // Sincroniza las especialidades con el medico
                /*let specialtiesIds = [];
                specialtiesList.forEach(item => {
                    specialtiesIds.push(item.id);
                });
                this.registerSpecialties(hospital_id, specialtiesIds);*/
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteRecord(id) {
        try {
            // console.log('Deleting Record: %s ', id);
            let response = await axios.delete(`${this.#baseUrl}/hospitals/${id}`);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async registerSpecialties(hospital_id, specialtiesIds) {
        try {
            let formData = new FormData();
            formData.append("hospital_id", hospital_id);
            formData.append("specialties_ids", specialtiesIds);
            let response = await axios.post(this.#baseUrl + '/register-specialties/hospitals', formData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteSpecialties(hospital_id, specialtiesIds) {
        try {
            let formData = new FormData();
            formData.append("hospital_id", hospital_id);
            formData.append("specialties_ids", specialtiesIds);
            let response = await axios.post(this.#baseUrl + '/delete-specialties/hospitals', formData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async uploadImage(hospital_id, picture_id, imageFile) {
        if (imageFile) {
            try {
                let formData = new FormData();
                formData.append("hospital_id", hospital_id);
                formData.append("picture_id", picture_id);
                formData.append("file", imageFile);
                let response = await axios.post(this.#baseUrl + '/upload-image/hospitals', formData);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async deleteImage(hospital_id, picture_id) {
        if (picture_id) {
            try {
                let formData = new FormData();
                formData.append("hospital_id", hospital_id);
                formData.append("picture_id", picture_id);
                let response = await axios.post(this.#baseUrl + '/delete-image/hospitals', formData);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async sendEmail(profile_id, email) {
        try {
            let response = await axios.get(`${this.#baseUrl}/send-email/hospitals?id=${profile_id}&email=${email}`);
            // console.log('Respuesta: ', response);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            // console.log('Error response: %s', error.response.data.message);
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
            // console.log(error.request);
            // console.error('Error request: ', error.request.data);
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }

}

/*console.log('Array de imagenes: ', formData.pictures);

let objeto1 = { id: 1, name: 'Ramon', post: 'La Habana'};
let objeto2 = { id: 2, name: 'Ernesto', post: 'Santiago'};
let objeto3 = { id: 3, name: 'Rene', post: 'Guantanamo'};
let array_objetos = [objeto1, objeto2, objeto3];
let data = {
    id: formData.id,
    name: formData.name,
    pictures: array_objetos
};*/
// axios.post(this.#baseUrl + '/update-record/hospitals', data)